import React from "react"
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5" },
  page: { className: "home-page banner5-page" },
  childWrapper: {
    className: "banner5-title-wrapper kf4eg1a2sc-editor_css",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <p>Pre Screening</p>
            </span>
          </span>
        ),
        className: "banner5-title",
      },
      {
        name: "explain",
        className: "banner5-explain",
        children: (
          <span>
            <p>Identify + Hire top talent before competitors.&nbsp;</p>
          </span>
        ),
      },
      {
        name: "content",
        className: "banner5-content",
        children: (
          <span>
            <span>
              <p>Interview more candidates than ever before.&nbsp;</p>
            </span>
          </span>
        ),
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
          className: "banner5-button",
          type: "primary",
          children: (
            <span>
              <p>Start Now</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: "banner5-image",
    children:
      "https://deephire.s3.amazonaws.com/websiteAssets/candidate-view.gif",
  },
}
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <span>
                <p>
                  Engage, Interview, and Hire Candidates with Pre Recorded
                  Interviews
                </p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Engage</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Email + SMS follows up with all interested candidates
                        faster than a recruiting team ever could. Including your
                        competitors.&nbsp;
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Interview</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    Automate scheduling and allow candidates to take interviews
                    on their own time, freeing up your team to focus on what
                    matters.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Hire</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    Review all candidates and hire the best with confidence. Get
                    feedback quickly while giving more time back to
                    managers.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Content10DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  OverPack: { className: "home-page content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children:
      "https://deephire.s3.amazonaws.com/websiteAssets/undraw_connection_b38q.png",
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
  title: {
    className: "content1-title",
    children: (
      <span>
        <span>
          <span>
            <p>Make sure every candidate has a fair shot.&nbsp;</p>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: "content1-content",
    children: (
      <span>
        <p>
          As long as a candidate can browse the internet, they can complete a
          One Click interview.&nbsp;Our technology ensures every candidate can
          take their interview on their own time, with their own devices -
          meaning you have a frictionless process.&nbsp;
        </p>
      </span>
    ),
  },
}
export const Content30DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  page: { className: "home-page content3" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Optimized for you. Optimized for candidates.&nbsp;</p>
          </span>
        ),
        className: "title-h1",
      },
    ],
  },
  block: {
    className: "content3-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>One Click Interviews</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  Candidates never have to use a mobile app to take interviews,
                  meaning a more inclusive and frictionless process.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block1",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>24/7 Tech Support</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  We handle all tech support, so your team won't have to. With
                  24/7 chat, all candidates get white glove service.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block2",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>Auto Invitations</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  Working on a large hiring project? Invite all candidates
                  through DeepHire to save time on cold calling.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block3",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>Built In Interview Prep</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  Give candidates a leg up in their interviews by preloading
                  example interview questions + answers.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block4",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>Continuous Improvement</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  Fine tune your interviewing process over time to ensure each
                  project is ROI positive with our reporting + analytics.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block5",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>Candidate Feedback</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  Improve candidate satisfaction by listening to post interview
                  feedback - collected for you by DeepHire.{" "}
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
}
export const Content110DataSource = {
  OverPack: {
    className: "home-page-wrapper content11-wrapper",
    playScale: 0.3,
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "image",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg",
        className: "title-image",
      },
      {
        name: "title",
        children: (
          <span>
            <span>
              <p>Ready to Double Your Effectiveness?&nbsp;</p>
            </span>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    See how DeepHire can enable you to interview more candidates
                    in less time while improving quality.&nbsp;
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: "title-content",
      },
    ],
  },
  button: {
    className: "",
    children: {
      a: {
        className: "button",
        href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
        children: (
          <span>
            <span>
              <p>Start Now</p>
            </span>
          </span>
        ),
      },
    },
  },
}
